var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticClass:"mt-10"},[_vm._v("Reports: Earnings")]),_c('div',{staticClass:"text-center mb-10"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),(!_vm.loading)?_c('section',[_c('h2',{staticClass:"mt-10"},[_vm._v("Earnings this week and last")]),_c('h3',{staticClass:"mt-10"},[_vm._v("Earnings this week by client")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.report.thisMonday,'ddd, MMM D'))+" to "+_vm._s(_vm._f("moment")(_vm.report.thisSunday,'ddd, MMM D'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.clientHeaders,"hide-default-footer":true,"items":_vm.report.earningsThisWeekClient,"loading":_vm.loading,"item-key":"id"},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_vm._v(_vm._s(_vm._f("numberFormat")(_vm._f("d100")(item.amount)))+" "),_c('span',{staticClass:"currency"},[_vm._v("CAD")])])]}}],null,false,3032654476)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("TOTAL")]),_c('th',[_vm._v(" "+_vm._s(_vm._f("numberFormat")(_vm._f("d100")(_vm.report.totalEarningsThisWeekClient)))+" CAD ")])])])],2),_c('h3',{staticClass:"mt-10"},[_vm._v("Earning this week by project")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.report.thisMonday,'ddd, MMM D'))+" to "+_vm._s(_vm._f("moment")(_vm.report.thisSunday,'ddd, MMM D'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.projectHeaders,"hide-default-footer":true,"items":_vm.report.earningsThisWeekProject,"loading":_vm.loading,"item-key":"id"},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_vm._v(_vm._s(_vm._f("numberFormat")(_vm._f("d100")(item.amount)))+" "),_c('span',{staticClass:"currency"},[_vm._v("CAD")])])]}}],null,false,3032654476)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("TOTAL")]),_c('th'),_c('th',[_vm._v(" "+_vm._s(_vm._f("numberFormat")(_vm._f("d100")(_vm.report.totalEarningsThisWeekProject)))+" CAD ")])])])],2),_c('h3',{staticClass:"mt-10"},[_vm._v("Earning last week by client")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.report.lastMonday,'ddd, MMM D'))+" to "+_vm._s(_vm._f("moment")(_vm.report.lastSunday,'ddd, MMM D'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.clientHeaders,"hide-default-footer":true,"items":_vm.report.earningsLastWeekClient,"loading":_vm.loading,"item-key":"id"},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_vm._v(_vm._s(_vm._f("numberFormat")(_vm._f("d100")(item.amount)))+" "),_c('span',{staticClass:"currency"},[_vm._v("CAD")])])]}}],null,false,3032654476)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("TOTAL")]),_c('th',[_vm._v(" "+_vm._s(_vm._f("numberFormat")(_vm._f("d100")(_vm.report.totalEarningsLastWeekClient)))+" CAD ")])])])],2),_c('h3',{staticClass:"mt-10"},[_vm._v("Earning last week by project")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.report.lastMonday,'ddd, MMM D'))+" to "+_vm._s(_vm._f("moment")(_vm.report.lastSunday,'ddd, MMM D'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.projectHeaders,"hide-default-footer":true,"items":_vm.report.earningsLastWeekProject,"loading":_vm.loading,"item-key":"id"},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_vm._v(_vm._s(_vm._f("numberFormat")(_vm._f("d100")(item.amount)))+" "),_c('span',{staticClass:"currency"},[_vm._v("CAD")])])]}}],null,false,3032654476)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("TOTAL")]),_c('th'),_c('th',[_vm._v(" "+_vm._s(_vm._f("numberFormat")(_vm._f("d100")(_vm.report.totalEarningsLastWeekProject)))+" CAD ")])])])],2)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }