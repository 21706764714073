<template>
  <v-container>
    <h1 class="mt-10">Reports: Hours per year</h1>
    <div class="text-center mb-10">
      <v-progress-circular v-if="loading" indeterminate color="primary" />
    </div>
    <section v-if="!loading">
      <h2 class="mt-10">Hours per year</h2>

      <section v-for="data in report.clients" :key="data.client.id">
        <h3 class="mt-5">{{ data.client.name }}</h3>
        <v-data-table
          :headers="headers"
          :hide-default-footer="true"
          :items="data.totalMinutes"
          :loading="loading"
          item-key="year"
        >
          <template v-slot:item.minutes="{item}">
            <span class="no-wrap">{{ item.minutes | d60 | numberFormat }}</span>
          </template>
          <template v-slot:item.amount="{item}">
            <span class="no-wrap">
              {{ item.amount | d100 | numberFormat }}
              <span class="currency">{{ data.client.currency }}</span>
            </span>
          </template>
        </v-data-table>
      </section>
    </section>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
  name: 'HoursPerYear',
  computed: {
    ...mapGetters('reports', {findReport: 'find'}),
    report() {
      return this.findReport('minutes-per-year');
    },
  },
  async created() {
    //this.$store.dispatch('reports/fetchReport', {report: 'summary'});
    this.loading = true;
    await this.loadReport('minutes-per-year');
    this.loading = false;
  },
  data: () => {
    return {
      headers: [
        {text: 'Year', value: 'year'},
        {text: 'Hours', value: 'minutes'},
        {text: 'Amount', value: 'amount'},
      ],
      loading: true,
    };
  },
  methods: {
    ...mapActions('reports', {loadReport: 'loadById'}),
  },
};
</script>

<style scoped lang="scss"></style>
