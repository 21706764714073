<template>
  <v-container>
    <h1 class="mt-10">Reports: Earnings</h1>
    <div class="text-center mb-10">
      <v-progress-circular v-if="loading" indeterminate color="primary" />
    </div>
    <section v-if="!loading">
      <h2 class="mt-10">Earnings this week and last</h2>

      <h3 class="mt-10">Earnings this week by client</h3>
      <p>
        {{ report.thisMonday | moment('ddd, MMM D') }} to
        {{ report.thisSunday | moment('ddd, MMM D') }}
      </p>

      <v-data-table
        :headers="clientHeaders"
        :hide-default-footer="true"
        :items="report.earningsThisWeekClient"
        :loading="loading"
        item-key="id"
      >
        <template v-slot:item.amount="{item}">
          <span class="no-wrap"
            >{{ item.amount | d100 | numberFormat }}
            <span class="currency">CAD</span></span
          >
        </template>
        <template slot="body.append">
          <tr>
            <th>TOTAL</th>
            <th>
              {{ report.totalEarningsThisWeekClient | d100 | numberFormat }} CAD
            </th>
          </tr>
        </template>
      </v-data-table>

      <h3 class="mt-10">Earning this week by project</h3>
      <p>
        {{ report.thisMonday | moment('ddd, MMM D') }} to
        {{ report.thisSunday | moment('ddd, MMM D') }}
      </p>

      <v-data-table
        :headers="projectHeaders"
        :hide-default-footer="true"
        :items="report.earningsThisWeekProject"
        :loading="loading"
        item-key="id"
      >
        <template v-slot:item.amount="{item}">
          <span class="no-wrap"
            >{{ item.amount | d100 | numberFormat }}
            <span class="currency">CAD</span></span
          >
        </template>
        <template slot="body.append">
          <tr>
            <th>TOTAL</th>
            <th></th>
            <th>
              {{ report.totalEarningsThisWeekProject | d100 | numberFormat }}
              CAD
            </th>
          </tr>
        </template>
      </v-data-table>

      <h3 class="mt-10">Earning last week by client</h3>
      <p>
        {{ report.lastMonday | moment('ddd, MMM D') }} to
        {{ report.lastSunday | moment('ddd, MMM D') }}
      </p>

      <v-data-table
        :headers="clientHeaders"
        :hide-default-footer="true"
        :items="report.earningsLastWeekClient"
        :loading="loading"
        item-key="id"
      >
        <template v-slot:item.amount="{item}">
          <span class="no-wrap"
            >{{ item.amount | d100 | numberFormat }}
            <span class="currency">CAD</span></span
          >
        </template>
        <template slot="body.append">
          <tr>
            <th>TOTAL</th>
            <th>
              {{ report.totalEarningsLastWeekClient | d100 | numberFormat }} CAD
            </th>
          </tr>
        </template>
      </v-data-table>

      <h3 class="mt-10">Earning last week by project</h3>
      <p>
        {{ report.lastMonday | moment('ddd, MMM D') }} to
        {{ report.lastSunday | moment('ddd, MMM D') }}
      </p>

      <v-data-table
        :headers="projectHeaders"
        :hide-default-footer="true"
        :items="report.earningsLastWeekProject"
        :loading="loading"
        item-key="id"
      >
        <template v-slot:item.amount="{item}">
          <span class="no-wrap"
            >{{ item.amount | d100 | numberFormat }}
            <span class="currency">CAD</span></span
          >
        </template>
        <template slot="body.append">
          <tr>
            <th>TOTAL</th>
            <th></th>
            <th>
              {{ report.totalEarningsLastWeekProject | d100 | numberFormat }}
              CAD
            </th>
          </tr>
        </template>
      </v-data-table>
    </section>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
  name: 'Earnings',
  computed: {
    ...mapGetters('reports', {findReport: 'find'}),
    report() {
      return this.findReport('earnings');
    },
  },
  async created() {
    //this.$store.dispatch('reports/fetchReport', {report: 'summary'});
    this.loading = true;
    await this.loadReport('earnings');
    this.loading = false;
  },
  data: () => {
    return {
      clientHeaders: [
        {text: 'Name', value: 'name'},
        {text: 'Amount', value: 'amount'},
      ],
      projectHeaders: [
        {text: 'Client', value: 'client'},
        {text: 'Project', value: 'project'},
        {text: 'Amount', value: 'amount'},
      ],
      loading: true,
    };
  },
  methods: {
    ...mapActions('reports', {loadReport: 'loadById'}),
  },
};
</script>

<style scoped lang="scss">
.v-card {
  text-align: center;
  .amount {
    color: white;
    font-size: 50px;
    line-height: 60px;
    display: block;
  }
  .label {
    color: white;
    display: block;
  }
}
</style>
